<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">订单管理</el-breadcrumb-item>
        <el-breadcrumb-item>预处理订单 （处理流程：导入 > 确认 > 配货 > 打单 > 复核发货）</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form label-width="90px" :model="querys" ref="querys" class="search-form">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col>
            <el-input v-model="querys.keyword" @keyup.enter="fetchs" prefix-icon="Search"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="订单时间" label-width="90px">
          <el-col>
            <el-date-picker v-model="dateRange" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间"
              unlink-panels :shortcuts="$util.getShortCuts()" style="max-width:210px;" @change="fetchs()">
            </el-date-picker>
          </el-col>
        </el-form-item>
        <!-- v-if="status==''" -->
        <el-form-item label="状态" prop="status" label-width="90px">
          <el-col style="width: 100px">
            <!-- <el-switch v-model="storeSwitch" active-text="在库" inactive-text="出库"></el-switch> -->
            <el-select v-model="status" clearable placeholder="请选择" @change="fetchs()">
              <!-- -5 订单挂起状态  -->
              <el-option key="-5" label="已挂起" value="-5"></el-option>
              <!-- 0 其他平台推送到系统的初始状态，审核后变成10待配货 -->
              <el-option key="0" label="待确认" value="0"></el-option>
              <!-- 10 待配货的状态  -->
              <el-option key="10" label="待配货" value="10"></el-option>
              <!-- 20 待复核的状态（打印快递单以及复核产品）  -->
              <el-option key="20" label="待复核" value="20"></el-option>
              <!-- 30 已发货的状态（已经复核的状态表示已发货）  -->
              <el-option key="30" label="已发货" value="30"></el-option>
              <el-option key="90" label="第三方发货" value="90"></el-option>
              <!-- 500 订单关闭状态  -->
              <el-option key="500" label="已关闭" value="500"></el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="创建时间" label-width="90px">
          <el-col>
            <el-date-picker v-model="importDateRange" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间"
              unlink-panels :shortcuts="$util.getShortCuts()" @change="fetchs()" style="max-width:210px;">
            </el-date-picker>
          </el-col>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="所属公司">
          <el-col>
            <el-tree-select ref="companys" v-model="companyId" :data="companys" @change="companyChange();" clearable
              check-strictly :render-after-expand="false" style="min-width: 220px;" />
          </el-col>
        </el-form-item>
        <el-form-item label="所属部门">
          <el-col>
            <el-tree-select ref="departments" v-model="departmentId" :data="departments" clearable check-strictly
              @change="fetchs()" :render-after-expand="false" style="min-width:120px" />
          </el-col>
        </el-form-item>

        <el-form-item label="订单平台" prop="platformCode" label-width="90px">
          <el-col style="width: 150px">
            <el-select v-model="platformCode" clearable placeholder="请选择" @change="fetchs()" allow-create filterable>
              <el-option key="douyin" label="抖音" value="douyin"></el-option>
              <el-option key="douyin-wdgj" label="抖音(店管家)" value="douyin-dgj"></el-option>
              <el-option key="zhaohang" label="招行" value="zhaohang"></el-option>
              <el-option key="guangfa" label="广发" value="guangfa"></el-option>
              <el-option key="kaola" label="猫享/考拉" value="kaola"></el-option>
              <el-option key="kaola" label="小米有品" value="youpin"></el-option>
              <el-option key="kaola" label="亚马逊" value="亚马逊"></el-option>
              <el-option key="kaola" label="拼多多传呈" value="拼多多传呈"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="回款金额" label-width="135px">
          <el-col>
            <el-select v-model="paybackPrice" placeholder="" clearable @change="fetchs()" style="min-width: 120px">
              <el-option key="1" label="有回款金额" value="1"></el-option>
              <el-option key="0" label="回款金额为0" value="0"></el-option>
              <el-option key="-1" label="无回款金额" value="-1"></el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="成本价" label-width="80px">
          <el-col>
            <el-select v-model="costPrice" placeholder="" clearable @change="fetchs()" style="min-width: 150px">
              <el-option key="1" label="有成本价" value="1"></el-option>
              <el-option key="0" label="成本价为0" value="0"></el-option>
              <el-option key="-1" label="无成本价" value="-1"></el-option>
            </el-select>
          </el-col>
        </el-form-item>
        
        <el-form-item label="供应链备注" prop="scmRemark" label-width="110px">
          <el-col style="width: 150px">
            <el-select v-model="scmRemark" clearable placeholder="请选择" @change="fetchs()" allow-create filterable>
              <el-option key="有货" label="有货" value="有货"></el-option>
              <el-option key="缺货" label="缺货" value="缺货"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="search" @click="fetchs()">搜索</el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <div class="toolbar">
      <el-button plain v-if="status == ''" type="primary" icon="upload" @click="importOrder()">导入订单</el-button>

      <!-- <el-button plain v-if="status == '0'" type="primary" icon="plus" @click="confirmOrderEvent('10')">确认订单</el-button> -->
      <el-dropdown v-if="status == '0'">
        <el-button plain type="primary">
          确认订单
          <el-icon>
            <ArrowDown />
          </el-icon>
        </el-button>

        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item icon="HelpFilled" @click="autoFitOrderEvent()">自动匹配</el-dropdown-item>
            <el-dropdown-item icon="Plus" @click="confirmOrderEvent('10')">确认订单</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>

      <!-- <el-button plain v-if="status=='10'" type="primary" icon="upload" @click="prepareOrder()">导入配货单</el-button> -->
      <el-dropdown v-if="status == '10'">
        <el-button type="success">
          配货单
          <el-icon>
            <ArrowDown />
          </el-icon>
        </el-button>

        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="exportPrepareOrder()">导出配货单</el-dropdown-item>
            <el-dropdown-item @click="importPrepareOrder()">导入配货单</el-dropdown-item>
            <el-dropdown-item @click="printPrepareOrder('prepareCheck')">打印配货单</el-dropdown-item>
            <!-- <el-dropdown-item>导入其他平台发货数据</el-dropdown-item> -->
          </el-dropdown-menu>
        </template>
      </el-dropdown>

      <el-dropdown v-if="status == '20'">
        <el-button type="primary">
          复核发货
          <el-icon>
            <ArrowDown />
          </el-icon>
        </el-button>

        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="printPrepareOrder('deliverycheck')">打印复核单</el-dropdown-item>
            <el-dropdown-item @click="reviewOrder()">扫码复核发货</el-dropdown-item>
            <el-dropdown-item @click="postInfoOrder()">导入快递单</el-dropdown-item>
            <el-dropdown-item @click="reviewBatchOrder()">批量发货</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <!-- <el-button plain v-if="status=='20'" type="primary" icon="CircleCheckFilled" @click="reviewOrder()">复核发货</el-button> -->

      <!-- <el-button plain v-if="status!='-1'" type="warning" icon="PriceTag" @click="pauseOrderEvent()">挂起订单</el-button>
      <el-button plain v-if="status==''" type="warning" icon="PriceTag" @click="confirmOrderEvent('10')">恢复订单</el-button>
      <el-button plain v-if="status!='-1'" type="danger" icon="CloseBold" @click="closeOrderEvent()">关闭订单</el-button> -->

      <el-button plain v-if="status == '10' || status == '20' || status == '30'" type="primary" icon="TakeawayBox"
        @click="printOrderPost" style="margin-left:15px;">电子面单</el-button>
      <el-dropdown v-if="status == '10' || status == '20' || status == '30'" style="margin-left:15px; display:none;">
        <el-button type="info">
          电子面单
          <el-icon>
            <ArrowDown />
          </el-icon>
        </el-button>

        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="printOrderPost()">合并打印面单</el-dropdown-item>
            <!-- <el-dropdown-item @click="printOrderCancel()">取消电子面单</el-dropdown-item> -->
            <!-- <el-dropdown-item>导入其他平台发货数据</el-dropdown-item> -->
          </el-dropdown-menu>
        </template>
      </el-dropdown>

      <el-dropdown style="margin-left:15px;">
        <el-button type="danger">
          操作订单
          <el-icon>
            <ArrowDown />
          </el-icon>
        </el-button>

        <template #dropdown>
          <el-dropdown-menu> <!-- v-if="status=='20'||status=='10'||status=='-5'||status=='0'||status==''" -->
            <el-dropdown-item @click="pauseOrderEvent()">挂起订单</el-dropdown-item>
            <el-dropdown-item @click="restoreOrderEvent()">恢复挂起</el-dropdown-item>
            <el-dropdown-item @click="splitOrderEvent()" divided>发货拆分</el-dropdown-item>
            <el-dropdown-item @click="markOrderOpen()" divided>第三方代发</el-dropdown-item>
            <el-dropdown-item @click="closeOrderEvent()">关闭订单</el-dropdown-item>

            <el-dropdown-item @click="deleteOrderEvent()">删除订单</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>

      <el-button plain v-if="status == '30' || status == '90'" type="primary" icon="Promotion" @click="platformDelivery"
        style="margin-left:15px;">渠道发货</el-button>

      <!-- 当前行是待复核或者已经发货的状态 -->
      <el-button plain v-if="currRow && (currRow.status == '30' || currRow.status == '20')" type="info" icon="Paperclip"
        @click="openReturnPage" style="margin-left:15px;">创建售后单</el-button>

      <el-button plain type="info" icon="VideoCameraFilled" @click="printOrderCheckOpen()"
        style="margin-left:15px;">快递复核</el-button>

      <el-dropdown icon="download" style="float:right; margin:0 3px;">
        <el-button plain type="info">
          数据操作
          <el-icon>
            <ArrowDown />
          </el-icon>
        </el-button>

        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="exportEvent()">导出发货明细</el-dropdown-item>

            <el-dropdown-item>
              <el-upload ref="upload" action :show-file-list="false" :multiple="false" :auto-upload="false"
                :on-change="uploadFeeEvent" style="display:inline-flex;vertical-align:middle; margin-right: 15px;">
                <template #trigger>上传更新订单</template>
              </el-upload>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <div style="clear:both;"></div>
    </div>

    <static-table class="table" :pager="pager" :columns="querys.columns" :currHandler="currHandler"
      :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler" :seleHandler="seleHandler">
      <!-- <template v-slot:platformOrderDate="scope">
        <i class="el-icon-time" v-show="scope.row.platformOrderDate"></i>
        <span style="margin-left: 0px">{{
          (scope.row.platformOrderDate || "").split(" ")[0]
        }}</span>
      </template> -->

      <template v-slot:orderNo="scope">
        <el-link v-if="scope.row.status == 0 || scope.row.status == 10 || scope.row.status == 20" type="primary"
          size="small" disabled>{{ scope.row.orderNo }}</el-link>
        <el-link v-else-if="scope.row.status == 30" type="success" size="small" disabled>{{ scope.row.orderNo
          }}</el-link>
        <el-link v-else-if="scope.row.status == 500" type="danger" size="small" disabled>{{ scope.row.orderNo
          }}</el-link>
        <el-link v-else-if="scope.row.status == -5" type="info" size="small" disabled>{{ scope.row.orderNo }}</el-link>
        <el-link v-else type="info" size="small" disabled>{{ scope.row.orderNo }}</el-link>
      </template>

      <template v-slot:status="scope">
        <el-link v-if="scope.row.status == 0" type="primary" size="small" disabled>待确认</el-link>
        <el-link v-else-if="scope.row.status == 10" type="primary" size="small" disabled>待配货</el-link>
        <el-link v-else-if="scope.row.status == 20" type="primary" size="small" disabled>待复核</el-link>
        <el-link v-else-if="scope.row.status == 30" type="success" size="small" disabled>已发货</el-link>
        <el-link v-else-if="scope.row.status == -5" type="info" size="small" disabled>已挂起</el-link>
        <el-link v-else-if="scope.row.status == 90" type="info" size="small" disabled>
          <span v-if="scope.row.bizModel == 'Brand'">品牌发</span>
          <span v-else-if="scope.row.bizModel == 'Third'">第三方</span>
          <span v-else>代发</span>
        </el-link>
        <el-link v-else-if="scope.row.status == 500" type="danger" size="small" disabled>已关闭</el-link>
        <el-link v-else type="info" size="small" disabled>错误</el-link>
      </template>
      <template v-slot:receiverName="scope">
        {{ (scope.row.receiverName || '').length >= 1 ? scope.row.receiverName.substr(0, 1) : '' }}<span
          v-if="(scope.row.receiverName || '').length >= 1"
          v-for="i in (scope.row.receiverName || '').length - 1">*</span>
      </template>
      <template v-slot:receiverTown="scope">
        {{ (scope.row.receiverTown || '').length >= 3 ? scope.row.receiverTown.substr(0, 3) : '' }}<span
          v-if="(scope.row.receiverTown || '').length >= 3"
          v-for="i in (scope.row.receiverTown || '').length - 1">*</span>
      </template>
      <template v-slot:receiverAddress="scope">
        {{ (scope.row.receiverAddress || '').length >= 5 ? scope.row.receiverAddress.substr(0, 5) : '' }}<span
          v-if="(scope.row.receiverAddress || '').length >= 5"
          v-for="i in (scope.row.receiverAddress || '').length - 5">*</span>
      </template>

      <template v-slot:scmRemark="scope">
        {{ (scope.row.scmRemark ? ("[" + (scope.row.scmRemark || '') + "]") : "")+(scope.row.platformRemark || '') + (scope.row.remark || '')  }}
      </template>

      <template v-slot:toolbar="scope">
        <!-- <el-button @click="watchOrder(scope.row)" type="primary" link size="small">[查看]</el-button> -->
        <!-- <el-button @click="close(scope.row)" type="primary" link size="small">[关闭]</el-button> -->
        <!-- <el-button @click="dele(scope.row)" type="primary" link size="small">[删除]</el-button> -->
        <el-link @click="watchOrder(scope.row)" type="primary" size="small">查看</el-link>
      </template>
    </static-table>
  </el-card>

  <static-dialog ref="detailDialog" class="dialog" title="订单详情" :opened="opened" :ok="modifyOrder">
    <el-form :model="forms" ref="forms" :label-width="110">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="收货人信息">
            <el-col :span="8">
              姓名：
              <span class="space20"> {{ forms.receiverName }}
                <!--{{ (forms.receiverName || '').length >= 1 ? forms.receiverName.substr(0, 1) : '' }}
                <span v-if="(forms.receiverName || '').length >= 1" v-for="i in (forms.receiverName || '').length - 1">*</span>-->
              </span>
            </el-col>
            <el-col :span="8">
              手机号：<span class="space20"> {{ forms.receiverMobile }}
                <!-- {{ forms.receiverMobile.length >= 11 && forms.receiverMobile.indexOf('*') == -1
                ?
                (forms.receiverMobile.substr(0, 3) + '****' + forms.receiverMobile.substr(7, 11)) : forms.receiverMobile
                }} -->
              </span>
            </el-col>
            <el-col :span="8">
              邮编：<span class="space20">{{ forms.receiverZipCode }}</span>
            </el-col>
            <el-col :span="24">
              地址：
              <span class="space20">
                {{ forms.receiverProvince }} {{ forms.receiverCity }} {{ forms.receiverArea }}
                {{ (forms.receiverTown || '').length >= 3 ? forms.receiverTown.substr(0, 3) : '' }}
                <span v-if="(forms.receiverTown || '').length >= 3"
                  v-for="i in (forms.receiverTown || '').length - 1">*</span>
              </span>
              <span class="space20">
                {{ (forms.receiverAddress || '').length >= 5 ? forms.receiverAddress.substr(0, 5) : '' }}
                <span v-if="(forms.receiverAddress || '').length >= 5"
                  v-for="i in (forms.receiverAddress || '').length - 5">*</span>
              </span>
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="平台信息">
            <el-col :span="12">
              订单号：<span class="space20">{{ forms.orderNo }}</span>
            </el-col>
            <el-col :span="12">
              订单时间：<span class="space20">{{ forms.platformOrderDate }}</span>
            </el-col>
            <el-col :span="24">
              订单平台：<span class="space20">{{ forms.platformName }} {{ forms.orderFrom }}</span>
              子单号：<span class="space20">{{ forms.subOrderNo || '无' }}</span>
              流水号：<span class="space20">{{ forms.platformPayNo || '无' }}</span>
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="货品信息">
            <el-col :span="24">
              <el-input v-model="forms.itemProductName" placeholder="下单的产品名称(可修改以便发货)"
                style="max-width: 95%;"></el-input>
            </el-col>
            <el-col :span="24">
              款号：<span class="space20">{{ forms.itemProductCode }}</span>
              条码：<span class="space20">{{ forms.itemSerialCode }}</span>
            </el-col>
            <el-col :span="24">
              货品金额：<span class="space20">{{ forms.itemTradeFee }}</span>
              货品数量：<span class="space40">{{ forms.itemQty }}</span>
              订单总金额：<span class="space20">{{ forms.platformTotalFee }}</span>
              货品总数：<span class="space20">{{ forms.platformTotalQty }}</span>
            </el-col>
            <el-col :span="24">
              平台描述：<span class="space40 redbox">{{ forms.itemSpec }}</span>
            </el-col>
            <el-col :span="24">
              用户备注：<span class="space20 redbox">{{ forms.platformRemark }}</span>
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="款式匹配信息">
            <el-col :span="24" v-if="forms.showSelect">
              <el-autocomplete style="width: 95%" v-model="chooseSku.value" :disabled="!forms.showSelect"
                :fetch-suggestions="searchSkuAsync" placeholder="选择或修改该订单对应的产品信息" @select="chooseSkuHandle">
              </el-autocomplete>
            </el-col>
            <el-col :span="12" v-if="(chooseSku.detailCode || '') != ''">
              系统款号：<span class="space20">{{ chooseSku.detailCode }}</span>
            </el-col>
            <el-col :span="12" v-if="(chooseSku.brandModelCode || '') != ''">
              品牌款号：<span class="space20">{{ chooseSku.brandModelCode }}</span>
            </el-col>
            <el-col :span="24" v-if="(chooseSku.detailName || '') != ''">
              款式名称：<span class="space20">{{ chooseSku.detailName }}</span>
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="发货批次信息">
            <el-col :span="10">
              <el-input v-model="forms.serialCode" style="width:180px;" placeholder="实际发货批次号"
                :disabled="forms.status != 10 && forms.status != 0"></el-input>
            </el-col>
            <el-col :span="8">
              <el-select v-model="forms.storeCode" placeholder="请选择库房" style="width: 120px"
                :disabled="forms.status != 10 && forms.status != 0">
                <el-option v-for="item in dict.stores" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-select v-model="forms.channel" placeholder="请选择渠道" style="width: 90%" disabled>
                <el-option v-for="item in dict.channels" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
          <el-form-item label="赠品及辅料">
            <el-col :span="10">
              <el-input v-model="forms.fittingCode" style="width:180px;" placeholder="赠品货号(有赠品则填)"
                :disabled="forms.status != 10 && forms.status != 0"></el-input>
            </el-col>
            <el-col :span="8">
              <el-select v-model="forms.fitStoreCode" placeholder="赠品库房" style="width: 120px" clearable
                :disabled="forms.status != 10 && forms.status != 0">
                <el-option v-for="item in dict.stores" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-select v-model="forms.auxMaterial" placeholder="发货辅料" style="width: 90%"
                :disabled="forms.status != 10 && forms.status != 0">
                <el-option v-for="item in dict.auxMaterials" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="备注">
            <el-col :span="24">
              <span class="space20 redbox">
                <el-input v-model="forms.remark" placeholder="可备注是否赠品以及发货的克重要求"></el-input>
              </span>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </static-dialog>

  <static-dialog ref="bizModelDialog" class="dialog" title="订单发货方式" :ok="markOrderEvent">
    <div style="width:100%; text-align: center;">
      <el-select v-model="bizModel" placeholder="" style="margin:10px auto;">
        <el-option key="Self" label="自发货" value="10" />
        <el-option key="Brand" label="品牌方发货" value="20" />
        <el-option key="Third" label="第三方发货" value="30" />
      </el-select>
    </div>
  </static-dialog>

  <static-dialog ref="uploaddialog" class="dialog" title="上传平台订单(可支持部分平台订单文件直接导入)" :okVisible="false">
    <uploader @freshen="fetchs()"></uploader>
  </static-dialog>

  <static-dialog ref="preparedialog" class="dialog" title="上传配货单，补充要发货的货品批次" :okVisible="false">
    <prepare @freshen="fetchs()"></prepare>
  </static-dialog>

  <static-dialog ref="reviewdialog" class="dialog" title="复核发货，需要将快递单导入至本系统或者使用本系统电子面单" :okVisible="false">
    <review @freshen="fetchs()"></review>
  </static-dialog>

  <static-dialog ref="postinfodialog" class="dialog" title="上传快递单，补充要发货的快递信息" :okVisible="false">
    <postinfo @freshen="fetchs()"></postinfo>
  </static-dialog>

  <static-dialog ref="postprintdialog" class="dialog" title="打印快递单/电子面单" :width="'100%'" :fullscreen="true"
    :ok="printBtn">
    <postprint ref="postprint" @freshen="fetchs()" :status="status" :selects="selects"></postprint>
  </static-dialog>
  <static-dialog ref="printCheckDialog" class="dialog" title="快递扫码复核发货(系统记录定位发货的录像)" :okVisible="false">
    <div style="width:100%; text-align: center;">
      <el-form :model="printCheck" label-width="120px" ref="printCheck" @submit.prevent>
        <el-form-item label="快递单号">
          <el-input v-model="printCheck.postNo" style="max-width:280px;" @keyup.enter="printOrderCheckSubmit()" />
        </el-form-item>
        <el-form-item v-if="printCheck.scanTime" label="记录时间" style="color:green">
          {{ $util.fmtDate(printCheck.scanTime) }}
        </el-form-item>
        <el-form-item v-if="printCheck.id" label="日志ID" style="color:green">
          {{ printCheck.id }}
        </el-form-item>
      </el-form>
    </div>
  </static-dialog>
</template>

<script>
import printJS from "print-js";
import uploader from "./upload.vue";
import prepare from "./prepare.vue";
import review from "./review.vue";
import postinfo from "./postinfo.vue";
import postprint from "./postprint.vue";
import StaticDialog from "@/components/static-dialog.vue";
import StaticTable from "@/components/static-table.vue";
import { uploader as uploaderEvent } from "@/api/subupload";
import {
  fetchPreInfos, fetchOrderDetail, delPreInfo, autoFitOrder, confirmOrder, closePreInfo, submitOrder, pausePreInfo, restoreOrder,
  markBizOrder, deliveryBatchOrder, deleteOrder, platformDelivery, SplitOrder, importOrderFee
} from "@/api/order";
import { fetchSkuList, fetchSkuByDetailCode } from "@/api/product";
export default {
  name: "PreInfoList",
  components: { StaticDialog, StaticTable, prepare, review, uploader, postinfo, postprint },
  data() {
    return {
      querys: {
        keyword: "",
        filters: [],
        columns: [
          { type: "selection", width: "55" },
          { type: "index", width: "65", label: "序号" },
          { type: "template", prop: "orderNo", label: "订单号", width: "210", sortable: true, searchable: true, showOverflowTooltip: true },
          { type: "template", prop: "status", label: "状态", width: "60" },
          { type: "hidden", prop: "platformCode" },
          { prop: "platformName", label: "平台", width: "75", searchable: true, sortable: true, showOverflowTooltip: true },
          { prop: "channel", label: "渠道", width: "70", searchable: true, sortable: true, showOverflowTooltip: true },
          { type: "hidden", prop: "supplierID" },

          //{ type: "template", prop: "platformOrderDate", label: "订单日期", width: "130", sortable: true, },
          { prop: "platformOrderDate", label: "订单日期", width: "145", sortable: true, },

          { prop: "platformTotalFee", label: "订单总价", width: "70" },
          { prop: "itemOriginalPrice", label: "产品原价", width: "70" },
          { prop: "itemTradeFee", label: "产品实付", width: "70" },
          { prop: "itemQty", label: "数量", width: "50" },

          { prop: "itemProductName", label: "名称", width: "140", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "itemProductCode", label: "渠道编码", width: "110", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "detailCode", label: "系统款号", width: "110", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "brandName", label: "品牌名称", width: "95", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "modelCode", label: "品牌款号", width: "110", sortable: true, searchable: true, showOverflowTooltip: true },

          { type: "template", prop: "receiverName", label: "收件人", width: "80", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "receiverMobile", label: "手机号", width: "100", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "receiverProvince", label: "省份", width: "90", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "receiverCity", label: "城市", width: "85", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "receiverArea", label: "区县", width: "80", searchable: true, showOverflowTooltip: true },
          { type: "template", prop: "receiverTown", label: "街道/镇", width: "90", searchable: true, showOverflowTooltip: true },
          { type: "template", prop: "receiverAddress", label: "详细地址", width: "120", searchable: true, showOverflowTooltip: true },
          // { prop: "receiverZipCode", label: "邮编", width: "70", showOverflowTooltip: true },
          //{ prop: "expressCode", label: "快递编号", width: "70", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "postName", label: "快递名称", width: "90", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "postNo", label: "快递单号", width: "130", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "platformPostFee", label: "快递费", width: "70", showOverflowTooltip: true },
          { prop: "platformOrderNo", label: "平台单号", width: "160", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "subOrderNo", label: "子单号", width: "120", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "orderCode", label: "商品单号", width: "120", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "platformPayDate", label: "支付时间", width: "142", sortable: true, showOverflowTooltip: true },

          { prop: "itemSerialCode", label: "条码", width: "120", sortable: true, searchable: true, showOverflowTooltip: true },

          { type: "hidden", prop: "bizModel", label: "业务类型" },

          // { prop: "platformPostFee", label: "运费", width: "60", },
          //   { prop: "platformTotalQty", label: "货品总数", width: "90", },
          //   { prop: "platformTotalFee", label: "订单合计", width: "90", },
          { prop: "platformRemark", label: "用户备注", width: "110", searchable: true, showOverflowTooltip: true },
          { prop: "remark", label: "商家备注", width: "110", searchable: true, showOverflowTooltip: true },

          { prop: "itemSpec", label: "规格", width: "90", searchable: true, showOverflowTooltip: true },
          { prop: "storeCode", label: "库房", width: "80", searchable: true, showOverflowTooltip: true },
          // { prop: "channel", label: "渠道", width: "80", searchable: true, showOverflowTooltip: true },
          { prop: "serialCode", label: "货号", width: "110", searchable: true, showOverflowTooltip: true },
          { prop: "fitStoreCode", label: "赠品库", width: "80", searchable: true, showOverflowTooltip: true },
          { prop: "fittingCode", label: "赠品编号", width: "110", searchable: true, showOverflowTooltip: true },

          { prop: "productPrice", label: "货品成本", width: "65", showOverflowTooltip: true },
          { prop: "costPrice", label: "综合成本", width: "65", showOverflowTooltip: true },
          { prop: "auxMaterial", label: "辅料名称", width: "90", showOverflowTooltip: true },
          { prop: "bagPrice", label: "辅料价格", width: "65", showOverflowTooltip: true },

          { prop: "taxPrice", label: "综合税", width: "70", showOverflowTooltip: true },

          { prop: "orderFrom", label: "来源", width: "65", searchable: true, showOverflowTooltip: true },
          { prop: "supplierName", label: "供应商名称", width: "180", sortable: true, searchable: true, showOverflowTooltip: true },

          { prop: "pointsPrice", label: "积分补贴", width: "65", showOverflowTooltip: true },
          { prop: "couponPrice", label: "优惠券补贴", width: "80", showOverflowTooltip: true },
          { prop: "othersPrice", label: "其他补贴", width: "65", showOverflowTooltip: true },
          { prop: "chargePrice", label: "平台扣点", width: "65", showOverflowTooltip: true },
          { prop: "paybackPrice", label: "应回款金额", width: "80", showOverflowTooltip: true },

          { prop: "createDate", label: "创建时间", sortable: true, width: "140", showOverflowTooltip: true },
          { prop: "createUserName", label: "创建人", sortable: true, searchable: true, width: "85", showOverflowTooltip: true, },
          { prop: "updateDate", label: "更新时间", sortable: true, width: "140", showOverflowTooltip: true },
          { prop: "updateUserName", label: "更新人", sortable: true, searchable: true, width: "85", showOverflowTooltip: true, },
          { type: "template", prop: "scmRemark", label: "备注", width: "85", searchable: true, showOverflowTooltip: true, fixed: 'right' },
          { type: "toolbar", label: "操作", width: "80" },
        ],
      },
      pager: {
        sidx: "platformOrderDate", //默认的排序字段
        sord: "desc",
      },
      forms: {},
      currRow: null,
      selects: [],
      status: "0",
      platformCode: '',

      bizModel: '10',
      dateRange: [
        // new Date(new Date(new Date().toLocaleDateString()).getTime()),
        // new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000)
      ],
      importDateRange: [],
      chooseSku: {},
      dict: {
        stores: [],
        channels: [],
        auxMaterials: [],
      },

      companys: [],
      companyId: '',
      departmentId: '',
      departments: [],
      paybackPrice: null,
      costPrice: null,

      scmRemark:'',

      printCheck: {}
    };
  },
  methods: {
    fetchs(methodName) {
      let that = this;
      if ((typeof methodName) == 'object') methodName = null;
      this.querys.filters = [];
      let { data, records, total, ...search } = {
        ...this.pager,
        ...this.querys,
      };
      search.sorters = [{ "name": "status", "asc": true }];

      if (this.status) {
        search.filters.push({
          //name: "stockRemain",
          name: "status",
          value: this.status,
          operate: 0,
        });
      }
      if (this.platformCode) {
        search.filters.push({
          name: "platformCode",
          value: '%' + this.platformCode + '%',
          operate: 5,
        });
      }
      if (this.dateRange && this.dateRange[0])
        search.filters.push({
          name: "platformOrderDate",
          value: this.$util.fmtDate(this.dateRange[0], "yyyy-MM-dd"),
          operate: 2,
        });
      if (this.dateRange && this.dateRange[1])
        search.filters.push({
          name: "platformOrderDate",
          value: this.$util.fmtDate(this.dateRange[1], "yyyy-MM-dd") + " 23:59:59",
          operate: 4,
        });

      if (this.importDateRange && this.importDateRange[0])
        search.filters.push({
          name: "createDate",
          value: this.$util.fmtDate(this.importDateRange[0], "yyyy-MM-dd"),
          operate: 2,
        });
      if (this.importDateRange && this.importDateRange[1])
        search.filters.push({
          name: "createDate",
          value: this.$util.fmtDate(this.importDateRange[1], "yyyy-MM-dd") + " 23:59:59",
          operate: 4,
        });

      if (this.companyId && this.companyId.length > 0) {
        search.filters.push({
          name: "companyId",
          value: this.companyId,
          operate: 6,
        });
      }
      if (this.departmentId != null && this.departmentId.length > 0) {
        search.filters.push({
          name: "departmentId",
          value: this.departmentId,
          operate: 6,
        });
      }

      if (this.paybackPrice == '1') {
        search.filters.push({
          name: "paybackPrice",
          value: '0',
          operate: 1,
        });
      }
      else if (this.paybackPrice == '0') {
        search.filters.push({
          name: "paybackPrice",
          value: '0',
          operate: 0,
        });
      }
      else if (this.paybackPrice == '-1') {
        search.filters.push({
          name: "paybackPrice",
          value: null,
          operate: 0,
        });
      }
      // if (this.paybackPrice == '0') { //已导入paybackPrice
      //   search.filters.push({
      //     name: "params",
      //     value: '%PaybackDate%',
      //     operate: 5,
      //   });
      // }
      // else if (this.paybackPrice == '-1') {
      //   search.filters.push({
      //     name: "params",
      //     value: '%PaybackDate%',
      //     operate: 5,
      //     not: true
      //   });
      // }
      if (this.costPrice == '1') {
        search.filters.push({
          name: "costPrice",
          value: '0',
          operate: 1,
        });
      }
      else if (this.costPrice == '0') {
        search.filters.push({
          name: "costPrice",
          value: '0',
          operate: 0,
        });
      }
      else if (this.costPrice == '-1') {
        search.filters.push({
          name: "costPrice",
          value: null,
          operate: 0,
        });
        // search.filters.push({
        //   name: "bizModel",
        //   value: 'Brand,Third',
        //   operate: 6, //如果是没有成本的就限制是第三方的
        // });
      }

      if (this.scmRemark) {
        search.filters.push({
          name: "scmRemark",
          value: '%' + this.scmRemark + '%',
          operate: 5,
        });
      }

      search.method = methodName || '';
      fetchPreInfos(search).then((res) => {
        if (search.method == 'Export') {
          this.$notify({
            title: "导出成功",
            message: res.content,
            type: "success",
            duration: 10000
          });
          window.location.href = process.env.VUE_APP_BASEURL + res.data;
        }
        else {
          this.pager = { ...res.data };
        }
      })
        .catch(() => {
          that.$message({ type: "info", message: "查询失败", });
        });
    },

    companyChange() {
      if (this.companyId) {
        this.departmentId = '';
        this.departments = [];
        var that = this;
        this.$kaung.departments(this.companyId).then((res) => {
          that.departments = res;
        });
      }
      this.fetchs();
    },

    exportEvent() {
      this.fetchs('Export');
    },
    autoFitOrderEvent() {
      if (this.selects.length == 0) {
        this.$message({ message: '请勾选至少一个订单' });
        return;
      }
      let that = this;
      this.$confirm('系统将会根据之前的订单发货款式信息，自动匹配要发货的款式信息和赠品信息(针对的是已实现系统对接的渠道平台，而未匹配到发货款式的订单) 是否继续?', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          let postData = [];
          for (let ii = 0; ii < this.selects.length; ii++) {
            var ele = this.selects[ii];
            postData.push({ id: ele.id })
          }
          autoFitOrder(postData).then((res) => {
            if (res.code === 200) {
              that.$alert(res.content, '提示');
              that.fetchs();
            } else {
              that.$alert(res.content, '提示');
            }
          }, (res) => {
            that.$alert(res.content, '提示');
          });
        })
        .catch(() => {
          that.$message({ type: "info", message: "匹配失败", });
        });
    },
    confirmOrderEvent(status) {
      if (this.selects.length == 0) {
        this.$message({ message: '请勾选至少一个订单' });
        return;
      }
      let that = this;
      let tipMessage = '确认审核勾选的订单, 该批订单将审核至待配货流程，是否继续?';
      var tipWarningArr = [];
      var numWarningArr = [];
      for (let ii = 0; ii < this.selects.length; ii++) {
        var ele = this.selects[ii];
        if ((ele.remark || '').indexOf('【系统') > -1) {
          tipWarningArr.push(ele.orderNo);
        }
        if (ele.itemQty > 1) {
          numWarningArr.push(ele.orderNo);
        }
      }
      if (tipWarningArr.length > 0)
        tipMessage = '<span style="color:red;">系统未识别到部分订单的备注/赠品等信息，是否继续审核勾选的订单?(可能出现漏发情况，请仔细核对确认)</span></br>'
          + tipWarningArr.join(',') + "";
      if (numWarningArr.length > 0)
        tipMessage = '<span style="color:red;">系统识别到部分订单用户有下单多件情况可能需要进行拆单处理，是否继续审核勾选的订单?(可能出现无法发货的情况，请仔细核对确认)</span></br>'
          + numWarningArr.join(',');

      this.$confirm(tipMessage, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          let postData = [];
          for (let ii = 0; ii < this.selects.length; ii++) {
            var ele = this.selects[ii];
            postData.push({ id: ele.id })
          }
          confirmOrder(postData, status).then((res) => {
            if (res.code === 200) {
              //that.$message({ type: "success", message: res.content, });
              that.$alert(res.content, '提示');
              that.fetchs();
            } else {
              that.$alert(res.content, '提示');
            }
          }, (res) => {
            that.$alert(res.content, '提示');
          });
        })
        .catch(() => {
          that.$message({ type: "info", message: "审核失败", });
        });
    },
    closeOrderEvent() {
      if (this.selects.length == 0) {
        this.$message({ message: '请勾选至少一个订单' });
        return;
      }
      let that = this;
      this.$prompt("<strong>请注意！！</strong><br><i style='font-size:0.7em; line-height:0.7em'>互联发货/一件代发等请选择第三方发货</i><br><i style='font-size:0.7em; line-height:0.7em'>退货/售后等需要关闭或删除已发货的订单其发货扣减的库存并不会恢复</i>",
        "请输入订单关闭的原因！",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPattern: /\S/,
          inputErrorMessage: '订单关闭原因不可为空',
          type: "warning",
          dangerouslyUseHTMLString: true,
        })
        .then(({ value }) => {
          console.log(value);
          closePreInfo(this.selects, value).then((res) => {
            if (res.code === 200) {
              that.$message({ type: "success", message: res.content, });
              that.fetchs();
            }
          });
        })
        .catch(() => {
          that.$message({ type: "info", message: "关闭失败", });
        });
    },
    markOrderOpen() {
      if (this.selects.length == 0) {
        this.$message({ message: '请勾选至少一个订单' });
        return;
      }
      this.$refs.bizModelDialog.toggle();
    },
    markOrderEvent() {
      if (!this.bizModel) {
        this.$message({ message: '请选择订单发货模式' });
        return;
      }
      let postData = [];
      for (let ii = 0; ii < this.selects.length; ii++) {
        var ele = this.selects[ii];
        postData.push({ id: ele.id })
      }
      let that = this;
      markBizOrder(postData, this.bizModel).then((res) => {
        if (res.code === 200) {
          that.$message({ type: "success", message: res.content, });
          that.$refs.bizModelDialog.toggle();

          that.fetchs();
        } else {
          that.$alert(res.content, '提示');
        }
      }, (res) => {
        that.$alert(res.content, '提示');
      });
    },
    deleteOrderEvent() {
      if (this.selects.length == 0) {
        this.$message({ message: '请勾选至少一个订单' });
        return;
      }
      let that = this;
      this.$confirm("确认删除勾选的订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let postData = [];
          this.selects.forEach(element => {
            postData.push({ id: element.id })
          });
          deleteOrder(postData).then((res) => {
            if (res.code === 200) {
              that.$message({ type: "success", message: res.content, });
              that.fetchs();
            }
          });
        })
        .catch(() => {
          that.$message({ type: "info", message: "删除失败", });
        });
    },
    pauseOrderEvent() {
      if (this.selects.length == 0) {
        this.$message({ message: '请勾选至少一个订单' });
        return;
      }
      let that = this;
      this.$prompt("请输入订单挂起的原因?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputErrorMessage: '订单挂起原因不可为空',
        type: "warning",
      })
        .then(({ value }) => {
          console.log(value);
          pausePreInfo(this.selects, value).then((res) => {
            if (res.code === 200) {
              that.$message({ type: "success", message: res.content, });
              that.fetchs();
            }
          });
        })
        .catch(() => {
          that.$message({ type: "info", message: "挂起失败", });
        });
    },
    restoreOrderEvent() {
      if (this.selects.length == 0) {
        this.$message({ message: '请勾选至少一个订单' });
        return;
      }
      let that = this;
      this.$confirm('确认将勾选的挂起订单恢复为待确认，是否继续?', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          let postData = [];
          for (let ii = 0; ii < this.selects.length; ii++) {
            var ele = this.selects[ii];
            postData.push({ id: ele.id })
          }
          restoreOrder(postData, 0).then((res) => {
            if (res.code === 200) {
              that.$message({ type: "success", message: res.content, });
              that.fetchs();
            } else {
              that.$alert(res.content, '提示');
            }
          }, (res) => {
            that.$alert(res.content, '提示');
          });
        })
        .catch(() => {
          that.$message({ type: "info", message: "操作失败", });
        });
    },
    dele(row) {
      let that = this;
      this.$confirm("此操作将删除本页选择数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delPreInfo(row.id).then((res) => {
            if (res.code === 200) {
              that.$message({
                type: "success",
                message: "删除成功",
              });
              that.fetchs();
            }
          });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "删除失败",
          });
        });
    },
    importOrder() {
      //this.$router.push({ path: "upload", query: {} });
      this.$refs.uploaddialog.toggle();
    },
    watchOrder(row) {
      //this.$router.push("detail?id=" + row.id);

      let that = this;
      fetchOrderDetail(row.id).then((res) => {
        that.forms = { ...res.data };
        that.forms.showSelect = false;

        let statusMark = that.forms.status;
        if ((that.forms.detailCode || '') == '' && (statusMark == 0 || statusMark == 10))
          that.forms.showSelect = true;
        else if (statusMark == -5 || statusMark == 0)
          that.forms.showSelect = true;
        that.$refs.detailDialog.toggle();
      });
    },
    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.pager.page = page;
      this.fetchs();
    },
    sizeHandler(rows) {
      this.pager.rows = rows;
      this.fetchs();
    },
    sortHandler(order) {
      this.pager.sidx = order.prop;
      this.pager.sord = order.order;
      this.fetchs();
    },
    seleHandler(rows) {
      this.selects = rows;
      //   console.log(this.pager.page, rows)
      //   this.querys.selects[this.pager.page - 1] = rows;
      //   //this.querys.selects.push(element)

      //   let items = [];
      //   this.querys.selects.forEach(element => {
      //     element.forEach(item => {
      //       items.push(item);
      //     });
      //   });
      //   console.log(items)
    },
    //搜索归属的SPU（请求后台数据）
    searchSkuAsync(queryString, callback) {
      console.log(queryString, callback);

      let search = {
        page: 1,
        rows: 10,
        columns: [
          { prop: "detailCode", searchable: true },
          { prop: "detailName", searchable: true },
          { prop: "detailUnit", searchable: true },
          { prop: "serialCode", searchable: true },
          { prop: "brandCode", searchable: true },
          { prop: "brandName", searchable: true },
          { prop: "brandModelCode", searchable: true },
          { prop: "factoryModelCode", searchable: true },
        ],
        keyword: this.chooseSku.value,
      };
      fetchSkuList(search).then((res) => {
        console.log(res);
        //res.data.data
        let inputOptions = [];
        res.data.data.forEach(function (item) {
          inputOptions.push({
            value: item.detailName + "[" + item.detailCode + "]" + (item.serialCode ? "[" + item.serialCode + "]" : ''),
            ...item,
          });
        });

        callback(inputOptions);
      });
    },
    //选择选中事件
    chooseSkuHandle(item) {
      this.chooseSku = item;
      this.forms.detailCode = item.detailCode;
      this.forms.detailName = item.detailName;
      this.forms.modelCode = item.brandModelCode;
      this.forms.brandName = item.brandName;
      console.log('chooseSkuHandle', this.forms);
    },
    opened() {
      this.chooseSku = {};

      let that = this;
      if (this.forms.detailCode) {
        fetchSkuByDetailCode(this.forms.detailCode).then((res) => {
          that.chooseSku = { ...res.data };
          console.log('opened-chooseSku', this.forms, this.dict.auxMaterials);
        });
      }
    },
    modifyOrder() {
      let that = this;
      console.log('modifyOrder', this.forms);
      let orderParams = null;
      if (this.forms.status == 0 || this.forms.status == -5) {//只有待确认、挂起的订单才能修改要发货的款号
        if ((this.forms.detailCode || '') == '' || (this.forms.brandName || '') == '') {
          this.$message({ type: 'info', message: '请选择该订单对应的系统款号及品牌' });
          return;
        }
        if ((this.forms.auxMaterial || '') == '') {
          this.$message({ type: 'info', message: '请选择该发货时对应的发货辅料' });
          return;
        }
        if (this.forms.brandName && this.forms.auxMaterial.split('(').length > 1 && this.forms.auxMaterial.split('(')[0].indexOf(this.forms.brandName.substr(0, 2)) == -1) {
          this.$message({ type: 'info', message: '发货选择的辅料与所属品牌不一致' });
          return;
        }
        let auxMaterial = this.dict.auxMaterials.filter(v => v.name == that.forms.auxMaterial);
        this.forms.bagPrice = auxMaterial.length > 0 ? auxMaterial[0].attribute : 0;

        //允许修改款号/添加赠品
        orderParams = {
          brandName: this.forms.brandName,
          detailCode: this.forms.detailCode,
          detailName: this.forms.detailName,
          serialCode: this.forms.serialCode,
          storeCode: this.forms.storeCode,
          fitStoreCode: this.forms.fitStoreCode,
          fittingCode: this.forms.fittingCode,
          modelCode: this.forms.modelCode,
          remark: this.forms.remark,
          itemProductName: this.forms.itemProductName,
          auxMaterial: this.forms.auxMaterial,
          bagPrice: this.forms.bagPrice,
        };
      }
      else if (this.forms.status == 10) { //已确认,待配货的状态 需要确定 发货批次号
        if ((this.forms.serialCode || '') == '') {
          this.$message({ type: 'info', message: '请选择该订单对应的货品编号' });
          return;
        }
        if ((this.forms.storeCode || '') == '') {
          this.$message({ type: 'info', message: '请选择该订单对应的库房信息' });
          return;
        }
        if ((this.forms.channel || '') == '') {
          this.$message({ type: 'info', message: '请选择该订单对应的渠道信息' });
          return;
        }

        orderParams = {
          serialCode: this.forms.serialCode,
          storeCode: this.forms.storeCode,
          channel: this.forms.channel,
          fittingCode: this.forms.fittingCode,
          fitStoreCode: this.forms.fitStoreCode,
          serialCode: this.forms.serialCode,
          storeCode: this.forms.storeCode,
        };
      } else {
        this.$message({ type: 'info', message: '订单状态异常' });
        return;
      }

      submitOrder(this.forms.id, orderParams).then((res) => {
        this.$message({ type: res.type, message: res.content, });
        this.fetchs();
      });
    },
    importPrepareOrder() {
      this.$refs.preparedialog.toggle();
    },
    exportOrder(method, columns) {
      this.querys.filters = [];
      let { data, records, total, ...search } = {
        ...this.pager,
        ...this.querys,
      };

      let checkIds = [];
      for (let ii = 0; ii < this.selects.length; ii++) {
        checkIds.push(this.selects[ii].id);
      }
      if (checkIds.length > 0) {
        this.querys.filters.push({
          name: "id",
          value: checkIds.join(','),
          operate: 6,
        });
      }

      if (this.status) {
        this.querys.filters.push({
          //name: "stockRemain",
          name: "status",
          value: this.status,
          operate: 0,
        });
      }
      if (this.platformCode) {
        this.querys.filters.push({
          name: "platformCode",
          value: this.platformCode,
          operate: 0,
        });
      }
      if (this.dateRange && this.dateRange[0])
        this.querys.filters.push({
          name: "platformOrderDate",
          value: this.$util.fmtDate(this.dateRange[0], "yyyy-MM-dd"),
          operate: 2,
        });
      if (this.dateRange && this.dateRange[1])
        this.querys.filters.push({
          name: "platformOrderDate",
          value: this.$util.fmtDate(this.dateRange[1], "yyyy-MM-dd") + " 23:59:59",
          operate: 4,
        });

      if (this.importDateRange && this.importDateRange[0])
        this.querys.filters.push({
          name: "createDate",
          value: this.$util.fmtDate(this.importDateRange[0], "yyyy-MM-dd"),
          operate: 2,
        });
      if (this.importDateRange && this.importDateRange[1])
        this.querys.filters.push({
          name: "createDate",
          value: this.$util.fmtDate(this.importDateRange[1], "yyyy-MM-dd") + " 23:59:59",
          operate: 4,
        });

      if (this.companyId && this.companyId.length > 0) {
        this.querys.filters.push({
          name: "companyId",
          value: this.companyId,
          operate: 6,
        });
      }
      if (this.departmentId != null && this.departmentId.length > 0) {
        search.filters.push({
          name: "departmentId",
          value: this.departmentId,
          operate: 6,
        });
      }
      
      if (this.scmRemark) {
        search.filters.push({
          name: "scmRemark",
          value: '%' + this.scmRemark + '%',
          operate: 5,
        });
      }


      search.method = method || 'ExportPrepare';

      let cols = [];
      columns.forEach(col => {
        let items = search.columns.filter(v => v.prop == col);
        if (items.length > 0) cols.push(items[0]);
      });

      search.columns = cols;
      fetchPreInfos(search).then((res) => {
        this.$notify({
          title: "导出成功",
          message: res.content,
          type: "success",
          duration: 10000
        });
        window.location.href = process.env.VUE_APP_BASEURL + res.data;
      });
    },
    exportPrepareOrder() {
      let columns = ['serialCode', 'platformName', 'storeCode', 'channel', 'brandName', 'itemProductName', 'modelCode', 'itemQty',
        'fittingCode', 'itemSpec', 'remark', 'orderNo', 'detailCode',
        'itemTradeFee', 'PlatformTotalFee', 'platformRemark', 'fitStoreCode', 'postName', 'postNo',
        'receiverName', 'receiverMobile', 'receiverProvince', 'receiverCity', 'receiverArea', 'receiverAddress', 'scmRemark'];
      this.exportOrder('ExportPrepare', columns);
    },
    // printPrepareOrder() {
    //   this.$alert('暂未上线，可通过导出配货单在Excel里进行打印', '提示')
    // },
    printPrepareOrder(checkType) {
      console.log(this.selects);
      let printData = [];

      let normalSum = 0; let fitSum = 0;
      for (let i = 0; i < this.selects.length; i++) {
        let row = this.selects[i];
        if ((row.itemProductName || '').indexOf('[辅料]') > -1 || (row.itemProductName || '').indexOf('[赠品]') > -1)
          fitSum++;
        else {
          normalSum++;
        }

        printData.push({
          '序号': (i + 1),
          '批次号': (row.serialCode || ''),
          '库房': (row.storeCode || ''),
          '渠道': (row.channel || ''),
          '名称': (row.itemProductName || '').length >= 13 ? (row.itemProductName.substr(0, 13) + '…') : (row.itemProductName || ''),
          '品牌款号': (row.modelCode || ''),
          '数量': row.itemQty,
          //'规格': (row.itemSpec || '').length >= 10 ? (row.itemSpec.substr(0, 10) + '…') : (row.itemSpec || ''),
          '平台': (row.platformName || ''),
          '用户': (row.receiverName || ''),
          '备注': ((row.scmRemark ? ("[" + (row.scmRemark || '') + "]") : "") + (row.platformRemark || '') + (row.remark || '')),
        });

        //发货产品附带有配置赠品信息
        if (row.fitStoreCode || row.fittingCode) {
          fitSum++;

          printData.push({
            '序号': '[赠]',
            '批次号': (row.fittingCode || ''),
            '库房': row.fitStoreCode,
            '渠道': row.channel,
            '名称': '[附赠品]' + (row.fittingCode || ''),
            '品牌款号': '',
            '数量': '',
            '平台': (row.platformName || ''),
            '用户': (row.receiverName || ''),
            '备注': ((row.platformRemark || '') + (row.remark || '')),
          });
        }
      }

      checkType = checkType || 'prepareCheck';
      var title = '配货单(预出库单)';
      if (checkType == 'deliverycheck') {
        title = '复核单(发货复核单)';
      }

      printJS({
        header: '<h3 class="">' + title + '(正常货品 ' + normalSum + ' 件，赠品 ' + fitSum + ' 件)</h3>',
        printable: printData,
        properties: ['序号', '批次号', '库房', '渠道', '名称', '品牌款号', '数量', //'规格',
          '平台', '用户', '备注'],
        type: 'json',
        gridHeaderStyle: 'font-size:0.8em;border: 1px solid #ccc;white-space:nowrap',
        gridStyle: 'font-size:0.8em;border: 1px solid #ccc;text-align:center;white-space:nowrap'
      })
    },
    reviewOrder() {
      this.$refs.reviewdialog.toggle();
    },
    postInfoOrder() { //导入快递单
      this.$refs.postinfodialog.toggle();
    },
    reviewBatchOrder() {
      if (this.selects.length == 0) {
        this.$message({ message: '请勾选至少一个订单' });
        return;
      }
      let tipMessage = "确认批量发货勾选的订单,本次操作将会自动扣减系统对应库存，是否继续?";
      let noPostNoLen = this.selects.filter(q => (q.postNo || '').length == 0).length;
      if (noPostNoLen > 0) tipMessage += "（勾选数据中有" + noPostNoLen + "个无快递信息）";

      let that = this;
      this.$confirm(tipMessage, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deliveryBatchOrder(this.selects).then((res) => {
            if (res.code === 200) {
              that.$message({ type: "success", message: res.content, });
              that.fetchs();
            }
          });
        })
        .catch(() => {
          that.$message({ type: "info", message: "操作失败", });
        });
    },
    printOrderPost() {
      //this.$alert('暂未上线', '提示')
      // if (this.selects.length == 0) {
      //   this.$message({ message: '请勾选至少一个订单' });
      //   return;
      // }
      //this.$refs.postprintdialog.toggle();
      let idsData = [];
      this.selects.forEach(element => {
        idsData.push(element.id)
      });
      this.$router.push('printInfo?status=' + this.status + '&platform=' + this.platformCode + '&ids=' + encodeURIComponent(idsData));
    },
    printBtn() {
      this.$refs.postprint.save();
    },
    printOrderCancel() {
      this.$alert('暂未上线', '提示')
    },

    printOrderCheckOpen() {
      this.printCheck = {};
      this.$refs.printCheckDialog.toggle();
    },
    printOrderCheckSubmit() {
      this.$kaung.fetch({
        url: '/SysOperateLog/',
        method: 'post',
        data: { logTitle: '快递扫码复核', action: 'SCAN', keyValue: this.printCheck.postNo }
      }).then((res) => {
        if (res.code == 200) {
          this.$message({ message: "扫码记录成功", type: "success", });
          this.printCheck = { scanTime: new Date(), id: res.data };
        }
      });
      return false;
    },

    platformDelivery() {
      if (this.selects.length == 0) {
        this.$message({ message: '请勾选至少一个订单' });
        return;
      }
      let that = this;

      this.$confirm('此操作会上传渠道订单的物流单号以及会将渠道订单状态变更为已发货，请确认上传勾选的订单', "渠道发货提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          platformDelivery(this.selects).then((res) => {
            if (res.code === 200) {
              that.$message({ type: "success", message: res.content, });
              that.fetchs();
            } else {
              that.$alert(res.content, '提示');
            }
          }, (res) => {
            that.$alert(res.content, '提示');
          });
        })
        .catch(() => {
          that.$message({ type: "info", message: "操作失败", });
        });
    },

    openReturnPage() {
      var row = this.currRow;
      if (!row) {
        if (this.selects.length == 0) {
          this.$message({ message: '请勾选对应的销售订单' });
          return;
        }
        if (this.selects.length > 1) {
          this.$message({ message: '单次仅支持勾选一个销售单来关连创建售后单' });
          return;
        }
        row = this.selects[0];
      }
      this.$router.push('addreturn?orderNo=' + row.orderNo + '&channel=' + row.channel + '&serialCode=' + row.serialCode);
    },
    splitOrderEvent() {
      if ((this.selects.length == 0 && !this.currRow) || (this.selects.length > 1)) {
        this.$message({ message: '请选择或者勾选一个要操作的订单' });
        return;
      }
      if (this.selects.length == 1 && this.currRow && this.selects[0].id != this.currRow.id) {
        this.$message({ message: '请选择或者勾选一个要操作的订单' });
        return;
      }
      let selectRow = this.currRow;
      if (!selectRow)
        selectRow = this.selects[0];

      let that = this;
      this.$prompt("<strong>是否确认将该订单拆分进行发货？请输入该订单要拆分的发货数。</strong><br><i style='font-size:0.7em; line-height:0.7em'>(用户下单数多件或者下单货品为套装以及自发货与三方代发混合发货等情况需要发货多件时才需要拆分)</i><br>",
        "订单发货拆分提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPattern: /^[1-9]\d*$/,
          inputErrorMessage: '订单要拆分的数量必须为正整数',
          inputPlaceholder: '请输入拆分的数量',
          inputValue: (selectRow.itemQty || 1),
          type: "warning",
          dangerouslyUseHTMLString: true,
        })
        .then(({ value }) => {
          SplitOrder(selectRow, value).then((res) => {
            if (res.code === 200) {
              that.$message({ type: "success", message: res.content, });
              that.fetchs();
            }
          });
        })
        .catch(() => {
          that.$message({ type: "info", message: "操作失败", });
        });
    },

    uploadFeeEvent(file) {
      var formData = new FormData();
      formData.append(file.name, file.raw);
      formData.append("fileType", 'excel');
      formData.append("dataType", this.$options.name);

      this.$refs.upload.clearFiles();

      uploaderEvent(formData)
        .then((res) => {
          if (res.code === 200) {
            this.$message({ message: "上传成功", type: "success", });
            this.dataId = res.data[0].id;
          } else
            this.$message.error("上传失败，请重试");
        })
        .then(() => {
          this.loading = false;
          importOrderFee(this.dataId).then(
            (res) => {
              this.fetchs();
              this.$alert(res.content, '提示');
            },
            (err) => {
              this.$alert(err.content, '提示');
            }
          );
        });

    }
  },
  watch: {
    companyId: function (newValue, oldValue) {
      if (newValue && newValue != oldValue)
        this.$util.setLocalstorage('companyIdStr', newValue);
      else
        this.$util.setLocalstorage('companyIdStr', '');
    },
    departmentId: function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.$util.setLocalstorage('departmentIdStr', newValue);
      }
    },
    pager: function (newValue, oldValue) {
      this.$util.setPageCache('keyword', newValue.keyword);
      if (newValue.page)
        this.$util.setPageCache('page', newValue.page);
      if (newValue.rows)
        this.$util.setPageCache('rows', newValue.rows);
      if (newValue.sidx)
        this.$util.setPageCache('sidx', newValue.sidx);
      if (newValue.sord)
        this.$util.setPageCache('sord', newValue.sord);

      this.$util.setPageCache('platformCode', this.platformCode);
    }
  },
  created: function () {
    this.importDateRange = this.$util.getCurrentDate();

    let status = this.$route.meta.status;
    this.status = (status != undefined) ? status.toString() : '';

    //if (this.status == '30') //只有已发货状态才默认加载缓存公司
    this.companyId = this.$util.getLocalstorage('companyIdStr') || '';
    this.departmentId = this.$util.getLocalstorage('departmentIdStr') || '';

    this.querys.keyword = this.$util.getPageCache('keyword');

    this.pager.page = parseInt(this.$util.getPageCache('page') || 1);
    this.pager.rows = parseInt(this.$util.getPageCache('rows') || 10);

    let sidx = this.$util.getPageCache('sidx');
    if (sidx) this.pager.sidx = sidx;
    let sord = this.$util.getPageCache('sord');
    if (sord) this.pager.sord = sord;

    this.platformCode = this.$util.getPageCache('platformCode');

    this.$kaung.companys().then((res) => {
      this.companys = res;
      this.companyChange();
    });

    this.$kaung.dictionary("stock", "store").then(res => this.dict.stores = res);
    this.$kaung.dictionary("stock", "channel").then(res => this.dict.channels = res);
    this.$kaung.dictionary("stock", "auxMaterial").then(res => this.dict.auxMaterials = res);
  }
};
</script>

<style lang="scss" scoped>
.space20 {
  margin-right: 20px;
  color: #000;
}

.space40 {
  margin-right: 40px;
  color: #000;
}

.redbox {
  color: red;
}
</style>
